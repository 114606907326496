export default {
    public: {
        copy_success: "复制成功",
    },
    header: {
        title1: "游戏",
        title2: "APPS",
        title3: "商店",
    },
    home: {
        text1_h5: "人工智能生成游戏的未来",
        text1_pc: "人工智能生成游戏的未来",
        text2: "我们正在使 AI 成为每个人发挥创造力和自由的工具。",
        text3_h5: "无限创意，由AI赋予生命",
        text3_pc: "无限创意，由AI赋予生命",
        text4: "我们正在 AI 协作和创造力生态系统中构建一个发现、探索和创造的游戏世界。",
        text5: "游戏",
        text6: "探索人工智能游戏的新时代 - 用身体控制游戏！",
        text7: "敬请期待！",
        text8: "我们的第一部AI作品。你输入你的指令，人工智能将继续这个故事。免费玩！",
        text9: "敬请期待！",
        text10: "关于",
        text11: "SUPE.AI是新世界的创造者，其使命是为人们探索广阔而生动的人工智能世界。我们让世界变得更有趣、更身临其境，因为它们是动态的，建立在 AI 技术的魔力之上，并且拥有无限的可能性。",
        text12: "通过开发我们的工具、产品和平台，我们将让世界更深入地了解人工智能的能力，同时创造引人入胜的娱乐体验，让人们乐在其中。",
        text13: "AI是SUPE的魔法！",
        text14: "加入我们的冒险！",
        text15: "我们正在尝试做一些大事，一些以前从未做过的事情……我们喜欢它。当我们征服巨人，屠龙，在蔚蓝的海洋中畅游时，我们会感到最有活力。",
    },
};
