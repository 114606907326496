import { createApp } from "vue";
import VueLazyload from "vue-lazyload";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./plugins/i18n";

import "./plugins/adaptive";
import "./styles/common.less";
import { myVanToast } from "./plugins/vant";

// 存储风格
const webStyle = process.env.VUE_APP_WEB_STYLE;
store.commit("updateWebStyle", webStyle ? webStyle : "");

const app = createApp(App);
app.config.globalProperties.$Toast = myVanToast;
app.use(store).use(i18n).use(router).use(VueLazyload).mount("#app");
