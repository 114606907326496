import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
const debug = process.env.NODE_ENV !== "production";

const state = {
    webStyle: "",
    lang: "zh-CN",
};

const mutations = {
    updateWebStyle(state, value) {
        state.webStyle = value;
    },
    updateLang(state, value) {
        state.lang = value;
    },
};
const actions = {};

export default createStore({
    state,
    mutations,
    actions,
    strict: debug,
    plugins: [createPersistedState()],
});
